import React, { FC, useState } from 'react';
import { useWindowSize } from 'react-use';
import { TRVoteItem } from '../../domains/Voting/api';
import DescriptionForCustom from '../DescriptionForCustom';
import CommentsList from '../../domains/Voting/Commentslist';


interface Props {
  item: TRVoteItem;
}
const VotingLinks: FC<Props> = ({ item }) => {
  const [block, setBlock] = useState<'description' | 'comments'>('description');
  const { width } = useWindowSize();
  return (
    <>
      <div className="panel__links">
        <div
          className={`btn  ${block === 'description' && 'active'}`}
          onClick={() => setBlock('description')}
        >
          <span>{width > 950 ? 'Voting description' : 'Description'}</span>
        </div>
        <div
          className={`btn comments ${block === 'comments' && 'active'}`}
          onClick={() => setBlock('comments')}
        >
          <span>{item.comments} comments</span>
        </div>
      </div>

      {item.type === 'yes-no' && block === 'description' && (
        <div
          className="panel__article"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      )}
      {item.type === 'custom' && block === 'description' && (
        <DescriptionForCustom data={item} />
      )}

      {block === 'comments' && <CommentsList />}
    </>
  );
};

export default VotingLinks;
