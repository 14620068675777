import React, { FC } from 'react';
import classnames from 'classnames';
import valueFormate from '../../helpers/valueFormate';

interface Props {
  percentYea: number;
  percentNay: number;
  yeaSum: number;
  NaYSum: number;
}

const VotingStatus: FC<Props> = ({
  percentYea,
  percentNay,
  yeaSum,
  NaYSum,
}) => {
  console.log({
    percentYea,
    percentNay,
    yeaSum,
    NaYSum,
  });
  return (
    <div className="panel__stat">
      <div className={classnames('line')}>
        <div className="line__bar" style={{ width: `${percentYea}%` }}>
          <div className="line__val">{valueFormate(yeaSum)}</div>
        </div>
        <button
          className="line__btn"
          type="button"
          // onClick={this.setVote(1)}
          disabled
        >
          <span>Yes</span>
        </button>
      </div>
      <div className={classnames('line')}>
        <div className="line__bar" style={{ width: `${percentNay}%` }}>
          <div className="line__val">{valueFormate(NaYSum)}</div>
        </div>
        <button
          className="line__btn"
          type="button"
          disabled
          // onClick={this.setVote(0)}
        >
          <span>No</span>
        </button>
      </div>

      {/* {isLoad && <Preloader />} */}
    </div>
  );
};

export default VotingStatus;
