import React, { FC, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import './style.scss';
import { fetchVoteList, fetchVoteListAndAdd } from './actions';
import {delVoteList, Filter, isFilterType, setFilter, setVotingType, Votings} from './reduser';
import { RootState } from '../../core/rootReducer';
import VotingTypes from '../../components/inputs/VotingTypes';
import { isVotingType } from './api';
import Preloader from '../../components/Preloader';
import VotingList from '../../components/VotingList';
import Filters from "../../components/inputs/Filters";

const NET = 'main';
const LIMIT = 100;
const VotingsComp: FC = () => {
  const dispatch = useDispatch();

  const voteList = useSelector<RootState, Votings['voteList']>(
    ({ votingsReducer }) => votingsReducer.voteList,
    shallowEqual
  );

  const votingType = useSelector<RootState, Votings['votingType']>(
    ({ votingsReducer }) => votingsReducer.votingType,
  );
  const filter = useSelector<RootState, Filter>(
    ({ votingsReducer }) => votingsReducer.filter,
  );

  useEffect(() => {
    const active = filter==='open';
    const noActive = filter==='closed';
    dispatch(
      fetchVoteList([
        {
          net: NET,
          limit: LIMIT,
          offset: 0,
          filter: { type: votingType,active,noActive },
          sort: { ts_start: 'desc' },
        },
      ])
    );
    return () => {
      dispatch(delVoteList());
    };
  }, [votingType,filter]);

  const onScrol = () => {
    const data = voteList.get('data');
    const offset = data?.items.length ?? 0;
    const all = parseInt(data?.all ?? '0', 10);
    const active = filter==='open';
    const noActive = filter==='closed';
    if (offset >= all) return;

    dispatch(
      fetchVoteListAndAdd([
        {
          net: NET,
          limit: LIMIT,
          offset,
          filter: { type: votingType ,active,noActive},
          sort: { ts_start: 'desc' },
        },
      ])
    );
  };
  const onChangeVotingType: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const { value } = e.target;
    if (!isVotingType(value)) return;
    dispatch(setVotingType(value));
  };
  const onChangeFilter: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const { value } = e.target;
    if (!isFilterType(value)) return;
    dispatch(setFilter(value));
  };

  const isLoading = voteList.get('isLoading');
  const votings = voteList.get('data')?.items;
  return (
    <div className="home__row home__row_filter">
      <div className="filter">
        <VotingTypes
          votingType={votingType}
          onChangeVotingType={onChangeVotingType}
        />
        <Filters filter={filter} onChange={onChangeFilter}/>
      </div>
      {isLoading ? (
        <Preloader />
      ) : (
        votings && <VotingList votings={votings} scrol={onScrol} />
      )}
    </div>
  );
};

export default VotingsComp;
