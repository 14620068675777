import React, { FC } from 'react';
import './VotingTypes.scss';
import type { Votings } from '../../../domains/Votings/reduser';
import {Filter} from "../../../domains/Votings/reduser";

interface PVotingTypes {
  filter: Filter;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
}
const Filters: FC<PVotingTypes> = ({ filter, onChange }) => {
  return (
    <div className="select select_types">
      <select
        className="select__selected"
        value={filter}
        onChange={onChange}
      >
          <option value="all">all </option>
          <option value="closed">Closed</option>
          <option value="open">Still open</option>
      </select>
    </div>
  );
};

export default Filters;
