import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Voting.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { delItem, Vote } from './reduser';
import { fetchVoteItem } from './actions';
import { RootState } from '../../core/rootReducer';
import Preloader from '../../components/Preloader';
import { getCntEN, getTimer } from '../../helpers/helper';
import VotingLinks from '../../components/VotingLinks';
import VotingStatus from '../../components/VotingStatus';
import htmlDecode from "../../helpers/htmlDecode";

const Voting: FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const dispatch = useDispatch();

  const item = useSelector<RootState, Vote['item']>(
    ({ voteReducer }) => voteReducer.item,
    shallowEqual
  );

  useEffect(() => {
    if (!id) return undefined;
    dispatch(fetchVoteItem([{ net: 'main', id }]));
    return () => {
      dispatch(delItem());
    };
  }, [id]);

  const itemData = item.get('data');
  if (item.get('isLoading')) return <Preloader />;
  if (item.get('error').isError) return <p>Voting not found</p>;
  if (!itemData) return null;

  const timer = getTimer(itemData.ts_end,itemData.ts_start);
  const isClosed = timer === 'end';
  const statVoting = isClosed ? 'Voting is closed' : 'Voting is open';
  const val = getCntEN(itemData.values);
  const sum = val.yea + val.nay;

  const percentNay = (val.nay / sum) * 100;
  const percentYea = (val.yea / sum) * 100;

  const starteDate = +new Date(itemData.ts_start);
  const thisDate = +new Date();
  const notSterted = thisDate < starteDate ;
  return (
    <>
      <div className={`home__panel `}>
        <div className="panel">
          <div className="panel__timer">
            <span>{timer}  {!notSterted && 'to vote'}</span>
          </div>
          <div className="panel__content">
            <div className="panel__text">
              <div className="panel__header">
                <span>{htmlDecode(itemData.name)}</span>
              </div>

              <hr />
              <VotingLinks item={itemData} />
            </div>

            <div className="panel__status">
              <span className="panel__status_txt">{htmlDecode(itemData.name)}</span>

              <div className="header">
                <span>{statVoting} </span>
              </div>

              {itemData.type === 'yes-no' && (
                <VotingStatus
                  percentNay={percentNay}
                  percentYea={percentYea}
                  yeaSum={val.yea}
                  NaYSum={val.nay}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Voting;
