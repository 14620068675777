import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

const BtnBack: FC = () => {
  const history = useHistory();

  function handleClick() {
    // history.goBack();
    history.push('/')

  }

  return (
    <button type="button" className="home__btn" onClick={handleClick}>
      <span>Back</span>
    </button>
  );
};

export default BtnBack;
