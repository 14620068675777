import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/rootReducer';
import { delCommentsList, Vote } from '../reduser';
import { fetchVoteCommentsList } from '../actions';
import type { TVoteComment } from '../api';
import './CommentsList.scss';
import htmlDecode from "../../../helpers/htmlDecode";

interface PCommentBlock {
  data: TVoteComment;
}
const CommentBlock: FC<PCommentBlock> = ({ data }) => {
  return (
    <div className="comments_ac__comment">
      <img src={`https://icons.metahash.io/${data.address}`} alt="" />
      <div className="text">
        <div className="text__account">
          {`${data.address.substring(0, 6)}....${data.address.slice(-10)}`}
        </div>
        <div className="text__text">{htmlDecode(data.text)} </div>
      </div>
    </div>
  );
};

const CommentsList: FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const dispatch = useDispatch();

  const commentsList = useSelector<RootState, Vote['commentsList']>(
    ({ voteReducer }) => voteReducer.commentsList,
    shallowEqual
  );

  useEffect(() => {
    if (!id) return undefined;
    dispatch(fetchVoteCommentsList([{ net: 'main', id }]));
    return () => {
      dispatch(delCommentsList());
    };
  }, [id]);

  const commentsListData = commentsList.get('data');

  return (
    <div className="comments_ac">
      {!commentsListData?.length && 'No comments'}
      {commentsListData?.map((item) => (
        <CommentBlock key={item.ts} data={item} />
      ))}
    </div>
  );
};

export default CommentsList;
