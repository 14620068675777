import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VotingsComp from './domains/Votings';
import './App.scss';
import Header from './components/Header';
import Voting from './domains/Voting';

function App() {
  return (
    <div className="home">
      <div className="home__bg" />
      <div className="home__content home__content_top">
        <Header />
        <Switch>
          <Route path="/voting/:id">
            <Voting />
          </Route>

          <Route path="/">
            <VotingsComp />
          </Route>
        </Switch>
      </div>
      <ToastContainer />
    </div>
  );
}

export default App;
