import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import RootReducer, { history } from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
  routerMiddleware(history),
  sagaMiddleware,
];

const store = configureStore({
  reducer: RootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});
sagaMiddleware.run(rootSaga);
export default store;
export type IStateType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
