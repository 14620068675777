import React, { FC } from 'react';
import './VotingTypes.scss';
import type { Votings } from '../../../domains/Votings/reduser';

interface PVotingTypes {
  votingType: Votings['votingType'];
  onChangeVotingType: React.ChangeEventHandler<HTMLSelectElement>;
}
const VotingTypes: FC<PVotingTypes> = ({ votingType, onChangeVotingType }) => {
  return (
    <div className="select select_types">
      <select
        className="select__selected"
        value={votingType}
        onChange={onChangeVotingType}
      >
        <option value="">all voting types</option>
        <option value="yes-no">Yes/No</option>
        <option value="custom">Custom</option>
      </select>
    </div>
  );
};

export default VotingTypes;
