import React, { FC, Fragment } from 'react';
import { TRVoteItem } from '../../domains/Voting/api';
import './DescriptionForCustom.scss';
import valueFormate from '../../helpers/valueFormate';

interface Props {
  data: TRVoteItem;
}
const DescriptionForCustom: FC<Props> = ({ data }) => {
  return (
    <div className="panel__article panel__article_multi">
      <p dangerouslySetInnerHTML={{ __html: data.description }} />

      <div className="options">
        {data.values.map((item) => {
          return (
            <Fragment key={item.value}>
              {/* <input */}
              {/*  type="radio" */}
              {/*  name="options" */}
              {/*  value={item.value} */}
              {/*  id={`options${item.value}`} */}
              {/*  disabled */}
              {/* /> */}
              {/* <label htmlFor={`options${item.value}`} className="options__item"> */}
              <div className="options__text">
                <span>{item.name}</span>
              </div>
              <div className="options__num">
                <span>{valueFormate(item.balance)}</span>
              </div>
              {/* </label> */}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default DescriptionForCustom;
