import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../core/api/fetchedData';
import type { TRVoteList } from './api';
import { VotingType } from './api';

export type Filter = 'all'|'closed'|"open"
export function isFilterType(p: string): p is Filter {
  return ['all', 'closed', 'open'].includes(p);
}

export interface Votings {
  voteList: FetchedData<TRVoteList>;
  votingType: VotingType;
  filter: Filter;
}
export const initialState: Votings = {
  voteList: genFetchedData<TRVoteList>(null),
  votingType: '',
  filter:'all'
};

const votingsSlice = createSlice({
  name: 'votings',
  initialState,
  reducers: {
    setVoteList(state, action: PayloadAction<Votings['voteList']>) {
      state.voteList = action.payload;
    },
    delVoteList(state) {
      state.voteList = initialState.voteList;
    },
    setVotingType(state, action: PayloadAction<Votings['votingType']>) {
      state.votingType = action.payload;
    },
    delVotingType(state) {
      state.votingType = initialState.votingType;
    },
    setFilter(state, action: PayloadAction<Filter>) {
      state.filter = action.payload;
    },
    delFilter(state) {
      state.filter = initialState.filter;
    },
  },
});

export const { setVoteList, delVoteList, setVotingType, delVotingType,setFilter,delFilter } =
  votingsSlice.actions;
export const votingsReducer = votingsSlice.reducer;
