import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { makeReqWithRD, TMakeReqWithRD } from '../../../core/api/makeReqWithRD';
import { fetchVoteCommentsList, fetchVoteItem } from '../actions';
import { getVoteCommentsList, getVoteItem } from '../api';
import { setCommentsList, setItem } from '../reduser';

export function* workerGetVoteCommentsList({
  payload,
}: ReturnType<typeof fetchVoteCommentsList>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getVoteCommentsList>>(makeReqWithRD, {
      fetcher: getVoteCommentsList,
      fill: setCommentsList,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}

export function* workerGetVoteItem({
  payload,
}: ReturnType<typeof fetchVoteItem>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getVoteItem>>(makeReqWithRD, {
      fetcher: getVoteItem,
      fill: setItem,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}
