import { SagaIterator } from 'redux-saga';
import { call, select, SagaReturnType, put } from 'redux-saga/effects';

import { setVoteList, Votings } from '../reduser';
import { makeReqWithRD, TMakeReqWithRD } from '../../../core/api/makeReqWithRD';

import {getVoteList, TPVoteList, TRVoteList} from '../api';
import { fetchVoteList, fetchVoteListAndAdd } from '../actions';
import { RootState } from '../../../core/rootReducer';
import { FetchedData, genFetchedData } from '../../../core/api/fetchedData';

const getVoteListFromReduser = ({
  votingsReducer,
}: RootState): Votings['voteList'] => votingsReducer.voteList;

export function* workerGetVoteList({
  payload,
}: ReturnType<typeof fetchVoteList>): SagaIterator<void> {

  try {
    yield call<TMakeReqWithRD<typeof getVoteList>>(makeReqWithRD, {
      fetcher: getVoteList,
      fill: setVoteList,
      parameters: payload,
    });
  } catch (e) {
    console.error({ e });
  }
}
export function* workerGetVoteListAndAdd({
  payload,
}: ReturnType<typeof fetchVoteListAndAdd>): SagaIterator<void> {
  const oldContent: Votings['voteList'] = yield select(getVoteListFromReduser);
  const oldData = oldContent.get('data');
  let receivedData: FetchedData<TRVoteList> = yield call(
    genFetchedData,
    oldData
  );
  receivedData = receivedData.set('isLoading', false);
  try {
    const result: SagaReturnType<typeof getVoteList> = yield call(
      getVoteList,
      payload
    );

    const oldItems = oldData?.items ?? [];
    receivedData = receivedData.set('data', {
      all: result.all,
      items: [...oldItems, ...result.items],
    });
    yield put(setVoteList(receivedData));
  } catch (e) {
    console.error({ e });
  }
}
