import React, { FC, useState } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import type { VoteItem } from '../../domains/Votings/api';
import { getCntEN, getCustomTypeVotes, getTimer } from '../../helpers/helper';
import './VoteCell.scss';
import valueFormate from '../../helpers/valueFormate';
import htmlDecode from "../../helpers/htmlDecode";

interface Props {
  vote: VoteItem;
}

const getVotins = (obj: VoteItem) => {
  const val = getCntEN(obj.values);

  const sum = val.yea + val.nay;
  const percent = {
    yea: (val.yea / sum) * 100,
    nay: (val.nay / sum) * 100,
  };

  if (obj.type === 'custom') {
    const options = obj.values.length;
    const count = getCustomTypeVotes(obj.values);
    return (
      <>
        <div className="line">
          <span>{options} options</span>
        </div>
        <div className="line">
          <span>{valueFormate(count)} votes</span>
        </div>
      </>
    );
  }
  if (obj.type === 'yes-no') {
    return (
      <>
        <div className={cn('line')}>
          <div
            className="line__yea"
            style={{ width: `${((percent.yea||0) < 2 )? 2:percent.yea}%` }}
          />
          <span className="line__txt">{valueFormate(val.yea)}</span>
        </div>

        <div className={cn('line')}>
          <div
            className="line__nay"
            style={{ width: `${((percent.nay||0) < 2) ? 2:percent.nay}%` }}
          />
          <span className="line__txt">{valueFormate(val.nay)}</span>
        </div>
      </>
    );
  }

  return null;
};

const VoteCell: FC<Props> = ({ vote }) => {
  const [showImg, setShowImg] = useState<boolean>(true);
  const timer = getTimer(vote.ts_end, vote.ts_start);

  const history = useHistory();

  function handleClick() {
    history.push(`/voting/${vote.address}`);
  }
  return (
    <div className="vote-cell" onClick={handleClick}>
      <div className="vote-cell__header">
        <div className="vote-cell__timer">{timer}</div>
        <div
          className={cn(
            'vote-cell__comments',
            +vote.comments > 0 ? 'active' : ''
          )}
        >
          {vote.comments}
        </div>
      </div>

      <div
        className={cn('vote-cell__theme', vote.icon && showImg ? 'img' : '')}
      >
        <span>{htmlDecode(vote.name)}</span>
        {showImg && (
          <img
            src={decodeURIComponent(vote.icon)}
            onError={() => setShowImg(false)}
            alt=""
          />
        )}
        <div>
          <span>{htmlDecode(vote.name)}</span>
        </div>
      </div>
      <div className="vote-cell__text">
        <span>
          {vote.description.length > 45
            ? `${htmlDecode(vote.description.substr(0, 45))}...`
            : htmlDecode(vote.description)}
        </span>
      </div>

      <div className="vote-cell__footer">
        <div className="vote-cell__stat">{getVotins(vote)}</div>
      </div>
    </div>
  );
};

export default VoteCell;
