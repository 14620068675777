import { createAction } from '@reduxjs/toolkit';

import type {TPVoteList} from "./api";


export const fetchVoteList = createAction<TPVoteList, 'votings/fetchVoteList'>(
  'votings/fetchVoteList'
);
export const fetchVoteListAndAdd = createAction<
    TPVoteList,
  'votings/fetchVoteListAndAdd'
>('votings/fetchVoteListAndAdd');
