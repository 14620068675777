export default function valueFormate(value: number): string {
  const mhc = value / 1e6;
  if (mhc > 10000)
    return `${new Intl.NumberFormat('ru-RU', {
      maximumFractionDigits: 0,
    }).format(mhc / 1000)}k`;
  return new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 1 }).format(
      mhc
  );
}
