import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import BtnBack from '../inputs/BtnBack';

const getTitle = function (pathname: string) {
  if (pathname === '/') {
    return <div className="home__title">#MetaHash Network Votings</div>;
  }

  return <BtnBack />;
};

const Header: FC = () => {
  const location = useLocation();
  return (
    <div
      className={cn('home__head', {
        noMain: location.pathname !== '/',
      })}
    >
      {getTitle(location.pathname)}
      <div className="home__logo" />
    </div>
  );
};

export default Header;
