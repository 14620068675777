import request from '../../core/api/request';
import { VotingType } from '../Votings/api';

export type TPVoteItem = [
  {
    net: 'test' | 'dev' | 'main';
    id: string;
  }
];

export type TVoteItemValues = {
  /** значение */
  value: string;
  /** название значения */
  name: string;
  /** кол-во голосов */
  cnt: string;
  /** баланс голосов */
  balance: number;
};

export type TRVoteItem = {
  /** адрес / идентификатор голосования */
  address: string;
  /** тип */
  type: VotingType;
  /** название */
  name: string;
  /** описание */
  description: string;
  /** коммисия */
  fee: string;
  /** дата окончания 2018-11-27 17:28:00 */
  ts_end: string;
  ts_start: string;
  /** ссылка на иконку */
  icon: string;
  /** кол-во комментариев */
  comments: string;
  /**  */
  values: TVoteItemValues[];
};

export const getVoteItem = request<TRVoteItem, TPVoteItem>({
  method: 'vote.item',
});

export type TPVoteCommentsList = [
  {
    net: 'test' | 'dev' | 'main';
    id: string;
    limit?: string;
    offset?: string;
  }
];

export type TVoteComment = {
  /** адрес пользователя */
  address: string;
  /** текст комментария */
  text: string;
  /** дата */
  ts: string;
};
export type TRVoteCommentsList = TVoteComment[];

export const getVoteCommentsList = request<
  TRVoteCommentsList,
  TPVoteCommentsList
>({
  method: 'vote.comments.list',
});
