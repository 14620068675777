export const ListErrors: ReadonlyMap<number, string> = new Map<number, string>([
  [0, 'NOT ERROR'],
  [1, 'DONT CREATE FOLDER'],
  [2, 'DONT CREATE KEY'],
  [3, 'INCORRECT PASSWORD'],
  [4, 'DONT SIGN '],
  [5, 'INCORRECT USER DATA '],
  [6, 'INCORRECT ADDRESS OR PUBLIC KEY '],
  [7, 'PRIVATE KEY ERROR '],
  [8, 'INCORRECT VALUE OR FEE '],
  [9, 'QR ENCODE ERROR '],
  [10, 'CLIENT ERROR '],
  [100, 'MESSENGER_SERVER_ERROR_ADDRESS_EXIST '],
  [101, 'MESSENGER SERVER ERROR SIGN OR ADDRESS INVALID '],
  [102, 'MESSENGER SERVER ERROR INCORRECT JSON '],
  [103, 'MESSENGER SERVER ERROR ADDRESS NOT FOUND '],
  [104, 'MESSENGER SERVER ERROR CHANNEL EXIST '],
  [105, 'MESSENGER SERVER ERROR CHANNEL NOT PERMISSION '],
  [106, 'MESSENGER SERVER ERROR CHANNEL NOT FOUND '],
  [107, 'MESSENGER SERVER ERROR OTHER '],
  [108, 'WALLET NOT UNLOCK '],
  [109, 'WALLET OTHER '],
  [110, 'INCOMPLETE USER INFO '],
  [111, 'CHANNEL TITLE INCORRECT '],
  [200, 'TRANSACTIONS SERVER SEND ERROR '],
  [201, 'TRANSACTIONS SENDED NOT FOUND '],
]);
