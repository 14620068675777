import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../core/api/fetchedData';
import type { TRVoteItem } from './api';
import { TRVoteCommentsList } from './api';

export interface Vote {
  item: FetchedData<TRVoteItem>;
  commentsList: FetchedData<TRVoteCommentsList>;
}
export const initialState: Vote = {
  item: genFetchedData<TRVoteItem>(null),
  commentsList: genFetchedData<TRVoteCommentsList>(null),
};

const voteSlice = createSlice({
  name: 'vote',
  initialState,
  reducers: {
    setItem(state, action: PayloadAction<Vote['item']>) {
      state.item = action.payload;
    },
    delItem(state) {
      state.item = initialState.item;
    },
    setCommentsList(state, action: PayloadAction<Vote['commentsList']>) {
      state.commentsList = action.payload;
    },
    delCommentsList(state) {
      state.commentsList = initialState.commentsList;
    },
  },
});

export const { setItem, delItem, setCommentsList, delCommentsList } =
  voteSlice.actions;
export const voteReducer = voteSlice.reducer;
