import { combineReducers } from '@reduxjs/toolkit';
import { createBrowserHistory, History } from 'history';
import { connectRouter } from 'connected-react-router';
import { votingsReducer } from '../domains/Votings/reduser';
import { voteReducer } from '../domains/Voting/reduser';

export const history = createBrowserHistory();
const createRootReducer = (h: History) =>
  combineReducers({
    votingsReducer,
    voteReducer,
    router: connectRouter(h),
  });

const RootReducer = createRootReducer(history);

export type RootState = ReturnType<typeof RootReducer>;
export default RootReducer;
