import { takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { workerGetVoteList, workerGetVoteListAndAdd } from './workers';
import { fetchVoteList, fetchVoteListAndAdd } from '../actions';

export default function* watchVotings(): SagaIterator {
  yield takeEvery<typeof fetchVoteList>(fetchVoteList, workerGetVoteList);
  yield takeEvery<typeof fetchVoteListAndAdd>(
    fetchVoteListAndAdd,
    workerGetVoteListAndAdd
  );
}
