import { takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { workerGetVoteCommentsList, workerGetVoteItem } from './workers';
import { fetchVoteCommentsList, fetchVoteItem } from '../actions';

export default function* watchVoting(): SagaIterator {
  yield takeEvery<typeof fetchVoteCommentsList>(
    fetchVoteCommentsList,
    workerGetVoteCommentsList
  );
  yield takeEvery<typeof fetchVoteItem>(fetchVoteItem, workerGetVoteItem);
}
