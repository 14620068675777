import request from '../../core/api/request';

export type VotingType = '' | 'yes-no' | 'custom';

export function isVotingType(p: string): p is VotingType {
  return ['', 'yes-no', 'custom'].includes(p);
}


export type VoteItem = {
  /** адрес / идентификатор голосования */
  address: string;
  /** тип */
  type: VotingType;
  /** название */
  name: string;
  /** описание */
  description: string;
  /** коммисия */
  fee: string;
  /** дата начала 2018-11-06 17:00:00 */
  ts_start: string;
  /** дата завершения 2018-11-27 17:00:00 */
  ts_end: string;
  /** ссылка на иконку */
  icon: string;
  /** кол-во комментариев */
  comments: string;
  values: VoteValues[];
};
export type TRVoteList = {
  /** общее кол-во активных голосований в базе */
  all: string;
  items: VoteItem[];
};
export type VoteValues = {
  /** значение */
  value: string;
  /**  название значения */
  name: 'Yes' | 'No';
  /** кол-во голосов */
  cnt: string;
  /** баланс голосов */
  balance: number;
};

export type TPVoteList = [
  {
    /** сеть */
    net: 'test' | 'dev' | 'main';
    /** ограничение кол-ва */
    limit: number;
    /** отступ */
    offset: number;
    filter?: {
      /** только активные голосования (если false или не указан выводит все) */
      active?: boolean;
      noActive?: boolean;
      /** тип голосования */
      type: VotingType;
    };
    /** сортировка */
    sort?: {
      /** по времени начала  */
      ts_start?: 'asc' | 'desc';
      /** по времени завершения */
      ts_end?: 'asc' | 'desc';
    };
  }
];
export const getVoteList = request<TRVoteList, TPVoteList>({
  method: 'vote.list',
});
