import React, { FC } from 'react';
import cn from 'classnames';
import { TRVoteList } from '../../domains/Votings/api';
import VoteCell from '../VoteCell';
import css from './VotingList.module.scss';

interface Props {
  votings: TRVoteList['items'];
  scrol: () => void;
}
const VotingList: FC<Props> = ({ votings, scrol }) => {
  const onScrollList: React.UIEventHandler<HTMLDivElement> = (e) => {
    const scrollBottom =
      e.currentTarget.scrollTop + e.currentTarget.offsetHeight ===
      e.currentTarget.scrollHeight;

    if (scrollBottom) {
      scrol();
    }
  };

  return (
    <div className={cn('home__scroll', css.scroll)} onScroll={onScrollList}>
      <div className={css.scroll_cell}>
        {votings.map((voting) => (
          <VoteCell vote={voting} key={voting.address} />
        ))}
      </div>
    </div>
  );
};

export default VotingList;
