import dayjs from 'dayjs';
import type { VoteValues } from '../domains/Votings/api';
import type { TVoteItemValues } from '../domains/Voting/api';

function getDeltaDate(date1:number, date2:number) {
  let delta = Math.floor((date1 - date2) / 1000);

  const days = Math.floor(delta / 86400);

  delta -= days * 86400;

  const hours = Math.floor(delta / 3600) % 24;

  delta -= hours * 3600;

  const minutes = Math.floor(delta / 60) % 60;

  delta -= minutes * 60;
  return { days, hours, minutes };
}


export function getTimer(ts_end: string,ts_start:string): string {
  const thisD = +new Date();
  const endD = +dayjs(ts_end);
  const starteDate = +dayjs(ts_start);
  if (thisD > endD) {
    return 'end';
  }
  if (thisD < starteDate) {
    const { days } = getDeltaDate(starteDate, thisD);
    return `${days} d. before voting `;
  }

  const { days, hours, minutes } = getDeltaDate(endD, thisD);

  const dateStr = `${days} d.  ${(`0${hours}`).slice(-2)}:${(`0${minutes}`).slice(
      -2
  )} `;

  return dateStr;
}


export const getCntEN = (values: VoteValues[] | TVoteItemValues[]) => {
  const obj = { yea: 0, nay: 0 };

  values.forEach((item) => {
    if (item.name === 'Yes') {
      obj.yea = +item.balance;
    }
    if (item.name === 'No') {
      obj.nay = +item.balance;
    }
  });

  return obj;
};

export const getCustomTypeVotes = (votes: VoteValues[] = []): number => {
  let count = 0;

  votes.forEach((item) => {
    count += +item.balance;
  });

  return count;
};

// export const getNotVoited = function(isvoited, value, type) {
//   if (!isvoited) {
//     return null;
//   }
//
//   if (value == 1 && type === 'nay') {
//     return 'noVoited';
//   }
//
//   if (value == 0 && type === 'yea') {
//     return 'noVoited';
//   }
//
//   return null;
// };
