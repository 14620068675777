import { toast } from 'react-toastify';
import { ListErrors } from '../../ListErrors';

interface ErrorProps {
  code: number;
  data: {
    mnemonic: string;
    description: string;
    fields: string[];
    location: string[];
  };
  message: string;
}

export default (
  error: ErrorProps | undefined,
  method: string,
  showError = true
): void => {
  const erMessage = error?.code ? ListErrors.get(error?.code) : undefined;

  if (error?.code === undefined) {
    toast.error('An error has occurred');
    return;
  }
  if (!showError) return;

  if (typeof erMessage === 'string') {
    toast.error(erMessage);
    return;
  }
  toast.error('The server is unavailable, please try again later.');
};
